import React from 'react'
import { Renderable } from '../types'
import AuthenticationProvider from './authentication-provider'

export interface ChassisProps {
  children: Renderable
}

function Chassis(props: ChassisProps) {
  return (
    <React.StrictMode>
      <AuthenticationProvider>{props.children}</AuthenticationProvider>
    </React.StrictMode>
  )
}

export default Chassis

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Chassis from '../Chassis'
import Header from '../Header'

function App() {
  return (
    <Chassis>
      <Container fluid>
        <Row>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </Chassis>
  )
}

export default App

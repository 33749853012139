import { Auth0Provider } from '@auth0/auth0-react'
import { Renderable } from '../types'

export interface AuthenticationProviderProps {
  children: Renderable
}

function AuthenticationProvider(props: AuthenticationProviderProps) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  if (domain === undefined || clientId === undefined) {
    throw new Error('Cannot authenticate user')
  }
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience="https://fitsheets.us.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
    >
      {props.children}
    </Auth0Provider>
  )
}

export default AuthenticationProvider
